@use "../global" as *;

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
  // background: $color-gray;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-text-center {
  text-align: center;
}
.is-text-right {
  text-align: right;
}
.is-text-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width-in;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
/*共通レイアウト*/
.sidebar {
  width: vwclamp(256,512);
  position: sticky;
  top: vwclamp(50,100);
  left: 0;
}
.layout--col2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  .layout--col2__cont {
    width: calc(100% - vwclamp(256,512) + vwclamp(40,80));
    margin-left: vwclamp(40,80);
  }
}
.mainWrap {
  position: relative;
  &.under {
    background: url(../img/bg_top.png) no-repeat right top / 100%,url(../img/bg_under.png) no-repeat left bottom / 100%;
  }
}
.underWrap {
  padding: vwclamp(55,110) vwclamp(20,170) vwclamp(280,562);
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__container {
    margin-top: vwclamp(50,100);
  }
}

@media screen and (max-width: 1080px) {
  /*共通レイアウト*/
  .sidebar {
    width: 100%;
    position: static;
  }
  .layout--col2 {
    flex-wrap: wrap;
    .layout--col2__cont {
      width: 100%;
      margin-left: 0;
    }
    &.spMenuStatic {
      flex-direction: column-reverse;
    }
  }
}
@include mq(sp) {
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: inline-block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
  .mainWrap {
    &.under {
      background: url(../img/bg_top_sp.png) no-repeat right top / 100%,url(../img/bg_under_sp.png) no-repeat left bottom / 100%;
    }
  }
  .underWrap {
    padding: vwclamp(81,162,$sp-width) 0 vwclamp(161,322,$sp-width);
    &__container {
      margin-top: vwclamp(50,100,$sp-width);
    }
  }
}
